@import "./mixins.scss";

$gradientStart: #99ce86;
$gradientMiddle: #f0c343;
$gradientEnd: #ff5d73;

$table-header-blue: #2494bd;
$table-header-col-border: #337ec9;
$scroll-width: 8px;
$header-height: 101px;
$left-col-width: 250px;
$cell-height: 50px;
$cell-width: 170px;

.empty-results-body {
  position: relative;
  left: -100px;
  margin: 100px auto;
  width: 200px;
  height: 200px;
  background: url("../assets//nrf.svg") no-repeat center center;
}

.grid-page-header {
  width: calc(100vw - 30px);
  margin-right: 10px;
  margin-left: 10px;
  .ep-switcher__switch-btn-text {
    color: #6e2c8a;
  }
  .ep-switcher__switcher input:checked + .switcher__slider,
  .ep-switcher__switcher .switcher__slider {
    background: #fff;
    border: 1px solid #fff;
    &:before {
      background: #507486;
      border: 1px solid #507486;
    }
  }

  .heat-map-legend {
    margin-top: -2px;
    width: 150px;
    padding: 1px 5px;
    height: 13px;
    background: linear-gradient(
      to right,
      $gradientStart 0%,
      $gradientMiddle 50%,
      $gradientEnd 100%
    );
    span {
      font-size: 12px;
      line-height: 10px;
    }
  }
}

#rmt-table {
  height: calc(100vh - 150px);
  width: calc(100vw - 10px);
  overflow: hidden;
  margin: 0px auto;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.interventions-row-container {
  min-width: 100%;
  transition: height 0.3s;
}

.population-interventions {
  font-size: 13px;
  background: linear-gradient(to top right, #2494bd3d 0%, #2494bd3d 49%, #18627d 50%, #18627d 100%);
  border-right: 1px solid #2494bd;
  transition: height 0.3s;
}

.rounded-top-left {
  border-top-left-radius: 27px;
}

.rounded-top-right {
  border-top-right-radius: 27px;
}

.interventions {
  height: 40px;
  min-width: 100%;
  border-bottom: 3px solid #ffbd29;
  background: #2494bd;
}

.interventions-group {
  width: 100%;
  color: #fff;
  padding: 0px 3px;
  height: 100%;
  font-size: 13px;
  line-height: 16px;
  background: #2494bd;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  &.current,
  &:hover {
    background: #ffbd29;
  }
}

.rmt-left-col-cell,
.grid-cell {
  flex: 0 0 $cell-width;
  height: $cell-height;
  line-height: $cell-height;
}

.rmt-header-cell {
  flex: 0 0 $cell-width;
  align-items: center;
  padding: 0px 3px;
  min-height: 40px;
  line-height: 20px;
  font-size: 13px;
  padding: 1px 3px;
  white-space: normal;
}

.rmt-pill {
  position: relative;
  background: #fff;
  width: 160px;
  min-height: 34px;
  line-height: 20px;
  margin: 8px auto;
  padding: 7px 10px;
  border-radius: 13px;
  &:hover {
    opacity: 0.75;
  }
  transition: all 0.3s;
  &.mute {
    opacity: 0.2;
  }
}

.grid-cell {
  &:last-child {
    border-right: none;
  }
}

#interventions-row {
  transition: height 0.3s;
}

.grid-header {
  width: 100%;
  color: #fff;
  .rmt-header-row > div {
    background: $table-header-blue;
    &.interventions-row-container {
      background: darken(#2494bd, 15%);
    }
  }
  .rmt-header-row {
    div.interventions-row {
      min-width: 100%;
    }
  }
}

.group-container {
  position: relative;
  align-items: center;
}

.group-label {
  text-align: right;
  height: 20px;
  line-height: 20px;
  color: #fff;
  background: #2494bd3d;
  min-width: 100%;
}

.rmt {
  width: 100%;
  height: 100%;
  text-align: center;
  * {
    box-sizing: border-box;
    border-collapse: collapse;
  }
  > div {
    height: 100%;
    width: 100%;
  }

  .grid-container {
    .grid-body {
      .grid-cell {
        color: #fff;
      }
    }
  }

  overflow: hidden;
  background: transparent;
  overflow: hidden;
  .grid-fixed-col {
    flex: 0 0 $left-col-width;
    width: $left-col-width;
    height: calc(100% - #{$scroll-width});
    border-right: 1px solid $table-header-col-border;
    overflow: hidden;
    .group-container {
      height: 100%;
      &:last-child {
        border-bottom: none;
      }
    }
    .populations {
      flex: 1 0 auto;
      text-align: right;
    }
    .rmt-left-col-cell {
      border-right: none;
      &:last-child {
        border-bottom: none;
      }
      .population {
        padding-right: 5px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;
        .population-label {
          text-align: right;
          line-height: 16px;
          margin-right: 5px;
          font-size: 14px;
        }

        .population-count {
          line-height: 20px;
          padding: 0px 3px;
          margin-left: 5px;
          font-size: 12px;
          color: #59ade4;
          display: inline-block;
          height: 20px;
          min-width: 20px;
          text-align: center;
          border-radius: 15px;
          background: #fff;
        }
      }
    }
  }

  .grid-container {
    width: 100%;
    position: relative;
    overflow: auto;
  }

  .grid-header {
    width: calc(100% - 12px);
    overflow: hidden;
  }
  .grid-body {
    max-width: calc(100% - 4px);
    @include custom-scroll-bar($scroll-width);
    overflow: scroll;
    .group-container {
      &:last-child {
        .rmt-body-row:last-child {
          .grid-cell {
            border-bottom: none;
            height: calc(#{$cell-height});
          }
        }
      }
    }
  }
}

.grid-filters {
  margin-left: 0px;
}

.grid-filter {
  min-width: 200px;
}
